import React, { useState, useEffect, useRef } from "react";
import "./Navbar.scss";
import { Link } from "gatsby";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const activeStyles = {
    textShadow: "1px 1px 0px var(--thulian-pink)",
  };

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="navbar__title">
        <Link to="/">goldenspace</Link>
      </div>
      <div className={`navbar__menu ${isOpen ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/" activeStyle={activeStyles}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/tour" activeStyle={activeStyles}>
              Tour
            </Link>
          </li>
          <li>
            <a
              href="https://store.boygolden.ca/collections/boy-golden"
              target="_blank"
            >
              Store
            </a>
          </li>
          <li>
            <Link to="/about" activeStyle={activeStyles}>
              About
            </Link>
          </li>
        </ul>
      </div>
      <div className="navbar__toggle" onClick={toggleMenu}>
        <div className="navbar__hamburger">
          <GiHamburgerMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
